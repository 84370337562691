import { theme } from '@zappyrent/hundop';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GroupBase,
  OptionProps,
  SingleValue,
  StylesConfig,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  StyledIcon,
  StyledLabel,
  StyledOptionIcon,
  StyledOptionIconWrapper,
  StyledWrapper,
} from './AsyncSelectInput.styles';
import {
  IAsyncSelectInputOption,
  IAsyncSelectInputProps,
} from './AsyncSelectInput.types';
const { Option } = components;

const AsyncSelectInput = ({
  id,
  label,
  isLoading,
  disabled,
  required = false,
  value,
  isClearable,
  isSearchable,
  optionIcon,
  user,
  showRequiredSymbol = true,
  lablelIcon,
  children,
  defaultOptions,
  onChange: customOnChange,
  loadOptions,
}: IAsyncSelectInputProps): JSX.Element => {
  const mainMenuColor = theme.colors[user]?.main;
  const paleMenuColor = theme.colors[user]?.mainPale;

  const [isFocused, setIsFocused] = useState(false);
  const [customPlaceholder, setCustomPlaceholder] = useState(label);
  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setMenuPortalTarget(document.body);
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);
  const { t } = useTranslation(['a11y']);

  const handleFocus = () => {
    const focusInputText = t('a11y:startTyping');
    setIsFocused(true);
    setCustomPlaceholder(`${focusInputText} ...`);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setCustomPlaceholder(label);
  };
  const styles: StylesConfig<
    IAsyncSelectInputOption,
    boolean,
    GroupBase<IAsyncSelectInputOption>
  > = {
    control: base => ({
      ...base,
      fontSize: '16px',
      height: '40px',
      border: 'none',
      borderBottom: '2px solid #C8C8C8',
      boxShadow: 'none',
      borderWidth: '1px !important',
      borderRadius: 0,
      backgroundColor: 'transparent',
      paddingLeft: lablelIcon ? '2.125rem' : '0px',
      ':disabled': {
        backgroundColor: '#EDF2F7',
      },
      ':hover': {
        borderColor: '#C8C8C8',
      },
    }),
    menuList: base => ({
      ...base,
      maxHeight: '200px',
    }),
    placeholder: base => ({
      ...base,
      fontSize: '16px',
      color: '#757575',
      padding: 0,
    }),
    option: base => ({
      ...base,
      fontSize: '16px',
      zIndex: 9999,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    clearIndicator: base => ({
      ...base,
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    valueContainer: base => ({
      ...base,
      padding: '2px 0',
    }),
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  function OptionWithIcon(props: OptionProps<IAsyncSelectInputOption>) {
    const { data, isSelected } = props;
    return (
      <Option {...props}>
        <StyledOptionIconWrapper>
          {optionIcon && (
            <StyledOptionIcon isSelected={isSelected}>
              {optionIcon}
            </StyledOptionIcon>
          )}
          <span>{data.label}</span>
        </StyledOptionIconWrapper>
      </Option>
    );
  }

  const handleChange = (
    selectedOption: SingleValue<IAsyncSelectInputOption>,
  ) => {
    if (selectedOption !== null) {
      customOnChange(selectedOption.value);
      return;
    }
    customOnChange(null);
  };

  return (
    <StyledWrapper
      isActive={isFocused}
      isEmpty={isEmpty(value)}
      inputValue={value}
    >
      {label && (
        <StyledLabel
          htmlFor={id}
          required={required}
          showRequiredSymbol={showRequiredSymbol}
        >
          {label}
        </StyledLabel>
      )}
      {optionIcon && <StyledIcon>{optionIcon}</StyledIcon>}
      <AsyncSelect
        ref={ref}
        cacheOptions
        defaultOptions={defaultOptions}
        placeholder={customPlaceholder}
        loadOptions={loadOptions}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={value =>
          handleChange(value as SingleValue<IAsyncSelectInputOption>)
        }
        isMulti={false}
        isDisabled={disabled}
        isLoading={isLoading}
        loadingMessage={() => `${t('a11y:searching')} ...`}
        noOptionsMessage={() => `${t('a11y:noOptions')}`}
        menuPosition="fixed"
        styles={styles}
        menuPortalTarget={menuPortalTarget}
        onKeyDown={handleKeyDown}
        isClearable={isClearable}
        isSearchable={isSearchable}
        components={{ Option: OptionWithIcon }}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: paleMenuColor,
            primary: mainMenuColor,
          },
        })}
      />
      {children}
    </StyledWrapper>
  );
};

export default AsyncSelectInput;
