import { Grid } from '@zappyrent/hundop';
import React from 'react';
import { useTranslation } from 'react-i18next';

import logoAzimut from '../../images/logos/Azimut.png';
import logoCDP from '../../images/logos/CDP.png';
import logoIlSole24Ore from '../../images/logos/IlSole24Ore.svg';
import logoLaRepubblica from '../../images/logos/LaRepubblica.svg';
import logoLaStampa from '../../images/logos/LaStampa.svg';
import {
  StyledWrapper,
  StyledLogoShowcase,
  StyledLogoShowcaseContent,
  StyledLogoShowcaseHeader,
  StyledLogoShowcaseList,
} from './LogoShowcase.styles';

export const newsLogos = [
  {
    href: 'https://www.ilsole24ore.com/art/zappyrent-portale-versa-canone-proprietario-anche-caso-morosita-ADdyPGHB',
    imageAlt: 'Il Sole 24 Ore',
    imageSrc: logoIlSole24Ore,
  },
  {
    href: 'https://www.repubblica.it/economia/rapporti/impresa-italia/tecnologia/2019/12/22/news/casa_la_sfida_degli_affitti_lunghi_ecco_l_airbnb_italiana_per_studenti_e_lavoratori-244133879/',
    imageAlt: 'La Repubblica',
    imageSrc: logoLaRepubblica,
  },
  {
    href: 'https://www.lastampa.it/topnews/economia-finanza/2021/01/28/news/canone-d-affitto-arriva-la-start-up-anti-morosita-paga-al-posto-del-proprietario-1.39831288',
    imageAlt: 'La Stampa',
    imageSrc: logoLaStampa,
  },
];

export const investorsLogos = [
  {
    imageAlt: 'CDP',
    imageSrc: logoCDP,
  },
  {
    imageAlt: 'Azimut',
    imageSrc: logoAzimut,
  },
];

interface LogoShowcaseProps {
  halfWidthContent?: boolean;
}

const LogoShowcase = ({
  halfWidthContent = false,
}: LogoShowcaseProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <Grid justifyContent="flex-start" alignItems="center" container>
        <StyledLogoShowcaseList isHalfWidth={halfWidthContent}>
          <StyledLogoShowcase>
            <StyledLogoShowcaseHeader>
              {t('product:zappyrentOnTheNews')}
            </StyledLogoShowcaseHeader>
            <StyledLogoShowcaseContent>
              {newsLogos.map((logo, index) => (
                <a key={index} href={logo.href}>
                  <img src={logo.imageSrc} alt={logo.imageAlt} />
                </a>
              ))}
            </StyledLogoShowcaseContent>
          </StyledLogoShowcase>
          <StyledLogoShowcase>
            <StyledLogoShowcaseHeader>
              {t('product:zappyrentInvestors')}
            </StyledLogoShowcaseHeader>
            <StyledLogoShowcaseContent bigImages>
              {investorsLogos.map((logo, index) =>
                logo.imageAlt === 'Azimut' ? (
                  <a
                    href="https://startupitalia.eu/167539-20211214-zappyrent-chiuso-round-da-12-milioni-di-euro"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img key={index} src={logo.imageSrc} alt={logo.imageAlt} />
                  </a>
                ) : (
                  <img key={index} src={logo.imageSrc} alt={logo.imageAlt} />
                ),
              )}
            </StyledLogoShowcaseContent>
          </StyledLogoShowcase>
        </StyledLogoShowcaseList>
      </Grid>
    </StyledWrapper>
  );
};

export default LogoShowcase;
