import { IGetCity } from './city.types';
import { httpGet } from './httpClient';

const apiGatewayUrl = process.env.GATSBY_GATEWAY_API;

export const getCities = async ({
  search_city,
}: {
  search_city?: string;
}): Promise<IGetCity[]> => {
  const { data } = await httpGet({
    url: `${apiGatewayUrl}/whirl/geo/city?q=${search_city?.toLowerCase()}`,
  });
  return data;
};
