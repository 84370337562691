import { rem } from 'polished';
import styled from 'styled-components';

import {
  IStyledLabelProps,
  IStyledOptionIconProps,
  IStyledWrapperProps,
} from './AsyncSelectInput.types';

export const StyledLabel = styled.label<IStyledLabelProps>`
  display: none;
  position: absolute;
  left: 0;
  color: ${({ theme }) => theme.colors.cross.grayson};
  font-size: ${({ theme }) => rem(theme.spacings.s)};
  line-height: 1.4;
  pointer-events: none;

  :after {
    display: ${({ showRequiredSymbol }) =>
      showRequiredSymbol ? 'inline-block' : 'none'};
    content: ${({ required }) => {
      return `'${required ? '*' : ''}'`;
    }};
    color: red;
    bottom: 4px;
    position: absolute;
  }
`;
export const StyledWrapper = styled.div<IStyledWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 1.8em;
  padding-bottom: 1.25em;
  flex: 1;
  ${({ inputValue }) =>
    !!inputValue &&
    `
    ${StyledLabel} {
      display: inline-block; 
      top: 6px;
    }
  `}

  ${({ isActive }) =>
    isActive &&
    `
    ${StyledLabel} {
      display: inline-block; 
      top: 6px;
    }
  `}
`;

export const StyledOptionIcon = styled.span<IStyledOptionIconProps>`
  width: ${({ theme }) => rem(theme.spacings.s)};
  height: ${({ theme }) => rem(theme.spacings.s)};
  pointer-events: none;

  svg {
    max-width: ${rem('22px')};
    height: ${rem('18px')};
    & path {
      fill: ${({ theme, isSelected }) => {
        if (isSelected) {
          return theme.colors.main;
        }
        return theme.colors.cross.grayson;
      }};
    }
  }
`;

export const StyledOptionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem('8px')};
`;

export const StyledIcon = styled.span`
  position: absolute;
  left: 0;
  bottom: ${rem('28px')};
  display: block;
  width: ${({ theme }) => rem(theme.spacings.m)};
  height: ${({ theme }) => rem(theme.spacings.m)};
  pointer-events: none;

  svg {
    max-width: ${rem('22px')};
    height: ${rem('24px')};
  }
`;
