import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

interface StyledLogoShowcaseListProps {
  isHalfWidth: boolean;
}

export const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.gradients.alternate};
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-direction: column;
    padding: ${rem('40px')} ${({ theme }) => rem(theme.spacings.m)};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: ${rem('80px')} 0;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const StyledLogoShowcaseList = styled.div<StyledLogoShowcaseListProps>`
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => rem(theme.spacings.m)};

  ${({ isHalfWidth }) =>
    isHalfWidth &&
    css`
      justify-content: flex-start;
      width: 50%;
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.l}) {
        width: 100%;
        justify-content: center;
      }
    `}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    justify-content: start;
  }
`;

export const StyledLogoShowcase = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => rgba(theme.colors.main, 0.4)};
  border-radius: ${rem('8px')};
  overflow: hidden;
`;

export const StyledLogoShowcaseHeader = styled.h2`
  background-color: ${({ theme }) => rgba(theme.colors.main, 0.6)};
  font-size: ${rem('14px')};
  font-weight: 700;
  margin: 0;
  padding: ${({ theme }) =>
    `${rem(theme.spacings.xs)} ${rem(theme.spacings.s)}`};
`;

export const StyledLogoShowcaseContent = styled.div<{ bigImages?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => rem(theme.spacings.l)};
  padding: ${({ theme }) =>
    `${rem(theme.spacings.m)} ${rem(theme.spacings.m)}`};

  & a {
    display: block;
    width: auto;
  }

  & img {
    display: block;
    width: auto;
    height: ${({ bigImages }) => (bigImages ? rem('60px') : rem('24px'))};
  }
`;
